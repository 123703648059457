import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Sweety from "../../components/Portfolio/Sweety"

const SweetyPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="Home" keywords={[`BMR`, `labels`]} />
    <Sweety />
  </Layout>
)

export default SweetyPage
